import { logError } from '@ardoq/logging';
import { ArdoqBackboneAjax } from './ardoq-backbone-ajax';
//
// Based off
// https://gist.github.com/twalker/72239adfd526cc230f2e
//

// Backbone now uses ES6 promises and model.save() doesn't return false

/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
export const addPromisesToBackbone = Backbone => {
  // Ardoq's impelemntation on BackboneAjax
  Backbone.ajax = ArdoqBackboneAjax;

  // Backbone.sync to resolve with models/collections as the settlement argument.
  const origSync = Backbone.sync;
  Backbone.sync = function sync(method, model) {
    return origSync.apply(this, arguments).then(function resolveWithModel() {
      return model;
    });
  };

  // Model.prototype.save to reject on validity errors, NOT return false.
  const origSave = Backbone.Model.prototype.save;
  Backbone.Model.prototype.save = function save() {
    const xhr = origSave.apply(this, arguments);
    // By this point, origSave has typically validated the model (emitting 'invalid' event),
    // and sync would have caught xhr errors (rejecting with xhr, and emitting 'error' event).
    // Rejecting with Model error instead of false.

    const returnValue = xhr !== false ? xhr : Promise.reject(this.validate());
    if (!returnValue || !returnValue.then) {
      logError(Error("Backbone save isn't returning a promise"), null, {
        xhrPrototype: Object.getPrototypeOf(xhr).constructor.name,
      });
    }
    return returnValue;
  };

  // Wrap isValid to explicitly state what it's checking
  Backbone.Model.prototype.isClientSideValid = function () {
    return this.isValid(arguments);
  };

  // Model.prototype.destroy to reject on errors, NOT return false.
  const origDestroy = Backbone.Model.prototype.destroy;
  Backbone.Model.prototype.destroy = function destroy() {
    const xhr = origDestroy.apply(this, arguments);
    // Resolve if destroy returns false
    // because it means that the model was not previously persisted,
    // but it was still destroyed
    return xhr !== false ? xhr : Promise.resolve(this);
  };

  return Backbone;
};
