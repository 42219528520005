import { maybeEmitUnauthorizedEvent } from '@ardoq/api';
import { ArdoqBackboneAjaxOptions } from 'backbone';

const readErrorParamsFromResponse = async (response: Response) => {
  /**
   * Reading here some failing response data,
   * to reproduce error structure similar to
   * XHR failing "thing"
   */
  const { status, statusText } = response;
  let responseText: undefined | string;
  let responseJSON: undefined | object;
  try {
    responseText = await response.text();
    // eslint-disable-next-line no-empty
  } catch (error) {}
  try {
    responseJSON = responseText ? JSON.parse(responseText) : undefined;
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return { status, statusText, responseText, responseJSON };
};

const throwJqAjaxLikeErrorFromNotOkResponse = async (
  response: Response,
  backboneAjaxParams: ArdoqBackboneAjaxOptions
) => {
  const errorParams = await readErrorParamsFromResponse(response);
  backboneAjaxParams.error?.(errorParams, response, backboneAjaxParams);
  throw { ...errorParams, ...backboneAjaxParams };
};

export const ArdoqBackboneAjax = async function (
  ardoqBackboneAjaxOptions: ArdoqBackboneAjaxOptions
) {
  const { type: method, url, data } = ardoqBackboneAjaxOptions;
  const shouldResponseContainBody = method !== 'DELETE';
  const response = await fetch(url, {
    method,
    headers: { 'Content-Type': 'application/json' },
    ...(data ? { body: data } : {}),
  });
  if (response.ok) {
    let result;
    try {
      if (shouldResponseContainBody) {
        result = await response.json();
      }
      ardoqBackboneAjaxOptions.success(
        result,
        response,
        ardoqBackboneAjaxOptions
      );
    } catch (error) {
      const errorParams = {
        ...(await readErrorParamsFromResponse(response)),
        statusText: 'Processing response JSON failed',
      };
      ardoqBackboneAjaxOptions.error?.(
        errorParams,
        response,
        ardoqBackboneAjaxOptions
      );
    }
  } else {
    maybeEmitUnauthorizedEvent(response.status);
    // expected to throw something similar to XHR
    await throwJqAjaxLikeErrorFromNotOkResponse(
      response,
      ardoqBackboneAjaxOptions
    );
  }
};
