import { log } from './sentryLogger';
import { registerImplementation } from '@ardoq/logging';
import { initializeSentry } from './sentry';
import * as profiling from '@ardoq/profiling';
import { APP_VERSION } from 'appConfig';
import { ArdoqError } from '@ardoq/common-helpers';

/**
 * logError is used to log errors which require immediate attention, which
 * expose bugs, or which negatively affect the user experience of the app.
 * @param error The caught error you are logging. If you have not caught an
 * error, create an `Error(message)` so as to make sure a stack trace will be
 * provided. If you are not logging an error, consider if it might make more
 * sense to use `logWarn`.
 *
 * @param message
 * Can be provided in cases where `error.message` does not yield enough
 * information. If `message` is not provided, `error.message` will be used.
 * - Should summarize what is wrong in a way which makes it easy to understand.
 * - Should in general not include parameters, as errors with distinct messages
 *   might not be considered the same error, making it difficult to get an
 *   overview of how frequently a given error occurs. If you need to pass
 *   parameters, use `extraData`.
 *
 * **In Sentry:**\
 * Will show up as the title of the error in the error overview.
 *
 * **In Kibana:**\
 * Will show up as the message in the error overview.
 *
 * @param extraData If there is data which is essential to debugging or tracking
 * down bad data, provide it here.
 * - Should not include more data than necessary.
 * - Should be easily stringifiable, so avoid deep or cyclic objects.
 */
const logError = (
  error: ArdoqError | Error,
  message?: string | null,
  extraData: SentryFingerprint & Record<string | number, unknown> = {}
) => {
  log({
    error,
    message,
    extraData,
    level: 'error',
  });
};

/**
 * logWarn is used to log less critical errors which do not require immediate
 * attention, but which for instance indicate inconsistent data, suboptimal
 * algorithms, etc.
 * @param error The caught error you are logging. If you have not caught an
 * error, create an `Error(message)` so as to make sure a stack trace will be
 * provided. If you you have caught an error, consider if it might make more
 * sense to use `logError`.
 *
 * @param message
 * Can be provided in cases where `error.message` does not yield enough
 * information. If `message` is not provided, `error.message` will be used.
 * - Should summarize what is wrong in a way which makes it easy to understand.
 * - Should in general not include parameters, as errors with distinct messages
 *   might not be considered the same error, making it difficult to get an
 *   overview of how frequently a given error occurs. If you need to pass
 *   parameters, use `extraData`.
 *
 * **In Sentry:**\
 * Will show up as the title of the error in the error overview.
 *
 * **In Kibana:**\
 * Will show up as the message in the error overview.
 *
 * @param extraData If there is data which is essential to debugging or tracking
 * down bad data, provide it here.
 * - Should not include more data than necessary.
 * - Should be easily stringifiable, so avoid deep or cyclic objects.
 */
const logWarn = (
  error: ArdoqError | Error,
  message?: string | null,
  extraData: SentryFingerprint & Record<string | number, unknown> = {}
) => {
  log({
    error,
    message,
    extraData,
    level: 'warn',
  });
};

export const initializeLogging = (
  dynamicMetadataFn: profiling.DynamicMetadataFn
) => {
  initializeSentry();
  registerImplementation({
    logError,
    logWarn,
  });
  profiling.initialize(APP_VERSION, dynamicMetadataFn);
};
