import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ArdoqId, EnhancedPresentation } from '@ardoq/api-types';
import {
  loadPresentation,
  LoadPresentationPayload,
  loadPresentationSuccess,
} from 'presentation/actions';

type LoadedPresentationState = {
  loadedPresentationId: ArdoqId | null;
  loadedSlideId: ArdoqId | null;
  presentation: EnhancedPresentation | null;
  organizationLabel: string | null;
  isCustomDomain: boolean;
};

const handleLoadPresentation = (
  state: LoadedPresentationState,
  {
    presentationId,
    selectedSlideId,
    organizationLabel,
    isCustomDomain,
  }: LoadPresentationPayload
): LoadedPresentationState => ({
  ...state,
  loadedPresentationId: presentationId,
  loadedSlideId: selectedSlideId,
  organizationLabel,
  isCustomDomain,
});

const handleLoadPresentationSuccess = (
  state: LoadedPresentationState,
  presentation: EnhancedPresentation
): LoadedPresentationState => ({
  ...state,
  presentation,
});

const loadedPresentation$ = persistentReducedStream(
  'loadedPresentation$',
  {
    presentation: null,
    loadedPresentationId: null,
    loadedSlideId: null,
    organizationLabel: null,
    isCustomDomain: false,
  },
  [
    reducer(loadPresentation, handleLoadPresentation),
    reducer(loadPresentationSuccess, handleLoadPresentationSuccess),
  ]
);

export default loadedPresentation$;
