import Backbone from 'backbone';
import BasicModel from 'models/basicmodel';
import { isInScopeDiffMode } from 'scope/scopeDiff';
import { isMergeFlowActive } from 'components/DiffMergeTable/isMergeFlowActive$';
import { getApiUrl } from 'backboneExtensions';
import type { UserBackboneModel } from 'aqTypes';
import { includes } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, @typescript-eslint/no-empty-object-type, @typescript-eslint/no-unused-vars
interface UserClass extends UserBackboneModel {}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
declare class UserClass extends BasicModel implements UserBackboneModel {
  constructor(attributes?: any, options?: any);
}
const Model: typeof UserClass = BasicModel.extend({
  idAttribute: '_id',
  urlRoot: `${getApiUrl(Backbone.Model)}/api/user`,
  validation: {
    name: {
      required: false,
      msg: 'Please provide your first and last name',
    },
    writeAccess: [
      {
        required: true,
        msg: 'Please check if user has write access',
      },
    ],
    email: [
      {
        required: true,
        msg: 'Please provide your email',
      },
      {
        pattern: 'email',
        msg: 'Email provided is not correct',
      },
    ],
  },
  defaults: {
    name: '',
    email: null,
    writeAccess: true,
    settings: {
      default: {},
      showReferenceListEditor: false,
    },
  },
  initialize: function (this: UserBackboneModel) {
    this.on('change:writeAccess', function (this: UserBackboneModel) {
      if (typeof this.attributes.writeAccess === 'string') {
        this.attributes.writeAccess = this.attributes.writeAccess === 'true';
      }
    });
    // Delete organizations map retrieved from current user.
    if (
      Object.prototype.hasOwnProperty.call(this.attributes, 'organizations')
    ) {
      delete this.attributes.organizations;
    }
  },
  hasWriteAccess: function (this: UserBackboneModel) {
    if (isInScopeDiffMode() && !isMergeFlowActive()) {
      return false;
    }

    return this.get('writeAccess') && this.isPublicUser() === false;
  },
  hasAcceptedTerms: function (this: UserBackboneModel) {
    return this.get('accepted-terms');
  },
  getName: function (this: UserBackboneModel) {
    return this.get('name');
  },
  getEmail: function (this: UserBackboneModel) {
    return this.get('email');
  },
  isPublicUser: function (this: UserBackboneModel) {
    return this.get('public') === true;
  },
  isOrgAdmin: function (this: UserBackboneModel, orglabel: string) {
    const actualOrgLabel = orglabel || this.getOrganization().label;
    return (
      actualOrgLabel === 'ardoq' ||
      includes(this.get('roles'), `${actualOrgLabel}/admin`)
    );
  },
  getRoles: function (this: UserBackboneModel) {
    return this.get('roles');
  },
  getOrganization: function (this: UserBackboneModel) {
    return this.get('organization');
  },
});
export default Model;
