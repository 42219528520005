import { initializeGraphicAssets } from '@ardoq/icons';
import { initializeLogging } from 'logging/logging';
import { initializeLocale } from 'utils/localeUtils';
import { DynamicMetadataFn } from '@ardoq/profiling';

export const initializeArdoqPackages = (
  dynamicMetadataFn: DynamicMetadataFn = () => ({})
) => {
  initializeLogging(dynamicMetadataFn);
  initializeLocale();
  initializeGraphicAssets();
};
