type GetNextPromise = (...args: any[]) => Promise<any>;

/**
 * A class that maintains a queue of promises
 */
export class PromiseQueue<T = any> {
  promiseQueue: Promise<T | void> = Promise.resolve();
  /**
   * Add a promise to the queue. It returns a promise
   * that will resolve when the added promise resolves.
   *
   * getNextPromise must catch errors, otherwise the queue will break.
   */
  add(getNextPromise: GetNextPromise) {
    this.promiseQueue = this.promiseQueue.then(getNextPromise).catch(error => {
      error.message = `The promise queue broke, getNextPromise should catch errors: ${error.message}`;
      throw error;
    });
    return this.promiseQueue;
  }

  /* Returns the latest state of the queue */
  getQueue() {
    return this.promiseQueue;
  }
}

/**
 * A higher order function to ensure that
 * a function which returns a promise
 * is queued to only run once at a time.
 *
 * Optionally, you can specify a PromiseQueue if multiple
 * promises should share the same queue
 *
 * getNextPromise must catch errors, otherwise the queue will break.
 */
export const queuePromises = (
  getNextPromise: GetNextPromise,
  promiseQueue = new PromiseQueue()
) => {
  return (...args: any[]) => {
    return promiseQueue.add(() => getNextPromise(...args));
  };
};
