import { APP_VERSION, isDevelopmentMode, isOnPremise } from 'appConfig';
import * as sentry from '@ardoq/sentry';
import { currentDateTimeISO } from '@ardoq/date-time';

export const initializeSentry = () => {
  if (isDevelopmentMode() || isOnPremise()) return;

  const sentryDsn = window.SENTRY_DSN;
  if (sentryDsn) {
    const clientVersion = APP_VERSION;
    sentry.initialize(
      sentryDsn,
      `frontend@${clientVersion}`,
      sentry.getEnvironment(window.location.hostname),
      0.1,
      currentDateTimeISO()
    );
  }
};
