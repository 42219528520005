import Backbone from 'backbone';
import { attributePrefix } from 'appConfig';
import * as encodingUtils from '@ardoq/html';
import { logError, logWarn } from '@ardoq/logging';
import type {
  BasicModel as BasicModelBackboneModel,
  ComponentBackboneModel,
  Reference,
} from 'aqTypes';
import { includes, isString, uniq } from 'lodash';
import CurrentUser from 'models/currentUser';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, @typescript-eslint/no-empty-object-type, @typescript-eslint/no-unused-vars
interface BasicModelClass extends BasicModelBackboneModel {}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
declare class BasicModelClass
  extends Backbone.Model
  implements BasicModelBackboneModel
{
  constructor(attributes?: any, options?: any);
}

const BasicModel: typeof BasicModelClass = Backbone.Model.extend({
  _cachedParsedDescription: undefined,
  mustBeSaved: false,
  initialize: function () {},

  setFieldValue: function (
    this: BasicModelBackboneModel,
    field: string,
    value: any
  ) {
    const fieldName = field.replace(attributePrefix, '');
    if (fieldName.length === 0) {
      return false;
    }
    const fixedVal =
      typeof value === 'string' ? value.replace(/^ /gi, '') : value;
    if (this.get(fieldName) !== fixedVal) {
      this.set(fieldName, fixedVal, {
        validate: true,
      });
    }
    return true;
  },
  /**
   * @protected
   * @param  obj The object for which to return the collection name.
   * @returns The name of the property containing a collection of sub-object IDs, such as "children" for a Component, or "components/references" for a Tag.
   */
  getObjectCollectionName: function (obj: Backbone.Model) {
    logError(
      Error(
        'Override this to get the collection the name of the attribute for this object.'
      ),
      null,
      { objectKeys: Object.keys(obj) }
    );
  },
  /**
   * @private
   * @param obj The object for which to return the collection.
   * @returns The collection of sub-object IDs. For a Component, this will be its child IDs, and for a Tag, it will be component and reference IDs.
   */
  getObjectCollection: function (
    this: BasicModelBackboneModel,
    obj: Backbone.Model
  ) {
    const collectionName = this.getObjectCollectionName(obj);
    if (!collectionName) {
      return null;
    }
    const collection: string[] = this.get(collectionName);
    return collection;
  },
  /**
   * A method used only by Filter.isTagIncluded.
   * @returns True if this BasicModel has obj in its collection of sub-objects. For Components, this method checks the children collection. For Tags, this method checks the components and references collection of the Tag.
   */
  contains: function (this: BasicModelBackboneModel, obj: Backbone.Model) {
    const collection = this.getObjectCollection(obj);
    return Boolean(collection?.some(id => id === obj.id));
  },
  add: function (this: BasicModelBackboneModel, obj: BasicModelBackboneModel) {
    if (!obj) {
      return null;
    }
    if (!obj.id && !isString(obj) && obj.once) {
      obj.once('sync', () => this.add(obj));
    } else {
      const collectionName = this.getObjectCollectionName(obj);
      const collection = this.getObjectCollection(obj)!.slice(); // Create a shallow copy of the array to avoid mutating it directly
      if (collection) {
        const elementToAdd = obj.id;
        if (!includes(collection, elementToAdd)) {
          collection.push(elementToAdd);
          const o: Record<string, string[]> = {};
          o[collectionName] = uniq(collection);
          this.set(o);
          this.trigger('change', this);
        }
      } else {
        logError(Error('Cannot add an unknown object type!'), null, {
          objectKeys: Object.keys(obj),
        });
      }
    }
  },
  /**
   * At the time of this writing, this function is only called on Tags.
   */
  remove: function (
    this: BasicModelBackboneModel,
    obj: ComponentBackboneModel | Reference
  ) {
    const collectionName = this.getObjectCollectionName(obj);
    if (collectionName) {
      const collection: string[] = this.get(collectionName);
      if (collection) {
        this.set(
          collectionName,
          collection.filter(id => id !== obj.id)
        );
        this.trigger('change', this);
      }
    } else {
      logWarn(
        Error(
          'Could not find collection for object, probably not saved or added to this workspace.'
        ),
        null,
        { id: obj ? obj.id : 'Undefined object' }
      );
    }
  },
  lock: function (this: BasicModelBackboneModel) {
    this.set('lock', CurrentUser!.id);
    this.save();
  },
  getLock: function (this: BasicModelBackboneModel) {
    return this.get('lock');
  },
  unlock: function (this: BasicModelBackboneModel) {
    if (CurrentUser!.canUnlock(this)) {
      this.set('lock', null);
      this.save();
    }
  },
  changedAndMustBeSaved: function (this: BasicModelBackboneModel) {
    return (!this.getLock() && this.mustBeSaved) || this.isNew();
  },
  name: function (this: BasicModelBackboneModel) {
    return encodingUtils.escapeHTML(this.get('name'));
  },
  getId: function (this: BasicModelBackboneModel) {
    return this.get('_id');
  },
  getDescription: function (this: BasicModelBackboneModel) {
    return this.get('description');
  },
  isSaved: function (this: BasicModelBackboneModel) {
    return this.get('_id') !== undefined;
  },
});

export default BasicModel;
